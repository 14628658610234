import { useState, useReducer, useEffect } from 'react';
import Stars from '../Stars.js';
import InfoIcon from '../OC/oc-info-icon';
import Modal from '../Modal';
import { Provider } from "react-redux";
import {connectLocation} from '../location/LocationConnectors';
import {FindAContractorConnector} from "../../redux/connectors/FindAContractorConnector";
import { If, RequestAQuoteModal, WrappedEmailRAQQuestionModalButton } from '../Utilities';
import GoogleMapReact from 'google-map-react';
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import {ContractorBadgeModal} from "../CertificationsModal";
import "../../../assets/stylesheets/components/filter.scss";
import AboutUs from './AboutUs';
import SocialLinks from './SocialLinks';
import DealerVideos from './DealerVideos';
import { mergeDigitalData } from "../helpers/analytics";
import Image from '../OC/oc-image';
import ReactOnRails from "react-on-rails";
import { getBadgeData } from '../FindAContractor/ContractorBadges';
import useRAQQuestionModal from '../../hooks/use_raq_question_modal.js';

const facDetail = css`
  section {
  overflow: hidden;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .contractor-header {
    display: flex;
    flex-direction: column;
    position: relative;

    @media(min-width: 768px) {
      flex-direction: row;
    }

    .logo {
      display: inline-flex;
      margin-bottom: 20px;

      @media(min-width: 768px) {
        display: inline-block;
        margin: 0 30px 0 0;
        width: 130px;
        min-height: 170px;
      }

      @media(min-width: 1200px) {
        max-width: 170px;
        min-height: 220px;
      }

      img {
        width: 100px;
        margin-right: 15px;
        align-self: center;

        @media(min-width: 768px) {
          width: 130px;
          margin: 0;
        }
      }
    }

    .contractor-info {
      margin-right: 15px;

      .contact-info {
        font-size: 15px;
        margin-bottom: 20px;

        @media(min-width: 768px) {
          margin-bottom: 0;
        }

        .phone {
          text-decoration: none;
        }

        .fa {
          color: #6d6d6d;
          font-size: 18px;
          margin-right: 5px;
        }

        p {
          margin: 0 0 15px;

          .phone {
            margin-right: 15px;
          }

          a {
            margin: 0 5px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .ratings-summary {
      margin: 0 0 15px;

      .ratings {
        span {
          margin: 0;
        }

        i {
          font-size: 24px;
        }
      }

      .review-count {
        font-size: 13px;
        margin: 0 5px;
      }
    }

    .cert-container {
      margin: 0 0 16px;
      display: block;

      @media(min-width: 768px) {
        width: fit-content;
        margin: 0 0 0 auto;
        display: inline-block;
      }

      .badges {
        max-width: 135px;
        margin-right: 15px;

        @media(min-width: 768px) {
          max-width: 200px;
          margin: 0 0 15px;
        }

        img {
          width: 100%;
        }
      }
    }

    .certifications {
      margin: 0;
      padding: 0;
      @media(max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        margin-right: 16px;
        display: flex;

        @media(min-width: 768px) {
          margin: 0 0 12px;
        }

        img {
          height: 20px;
          width: 20px;
          object-fit: contain;
        }

        .cert-info {
          display: none;
          color: #333;

          a {
            color: #6d6d6d;
          }

          @media(min-width: 768px) {
            display: inline-block;
          }
        }
      }
    }
  }

  .feedback {
    text-align: center;

    .score {
      color: black;
      align-items: center;
      display: inline-flex;
      font-family: 'oswald';
      margin-bottom: 10px;
      font-size: 80px;
      line-height: 80px;

      @media(min-width: 1200px) {
        font-size: 112px;
        line-height: 112px;
      }

      img {
        height: 30px;
        margin-right: 5px;

        @media(min-width: 1200px) {
          height: 45px;
          margin-right: 15px;
        }
      }

      sup {
        position: relative;
        top: -30px;
        font-size: 21px;
        top: -22px;

        @media(min-width: 1200px) {
          font-size: 32px;
          top: -30px;
        }
      }
    }

    label {
      font-family: 'roboto';
      color: #6D6E6E;
      display: block;
      text-transform: uppercase;
      font-size: 16px;


      @media(min-width: 1200px) {
        font-size: 18px;
      }
    }
  }


  .ratings {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 8px;

      .fa {
        font-size: 16px;
      }

      span {
        font-family: 'OCRoboto';
        font-size: 14px;
        font-weight: 700;
        margin-left: 5px;
      }

      label {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }

      input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid #949494;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
        }

        &:checked {
          border-color: #d40f7d;
          background-color: #d40f7d;

          & + .check {
            display: block;
          }
        }

        & + .check {
          display: none;
          height: 7px;
          width: 13px;
          border-left: 3px solid white;
          border-bottom: 3px solid white;
          position: absolute;
          transform: rotate(-45deg);
          left: 3px;
          top: 8px;
        }
      }

      &.disabled {
        color: #ddd;

        label {
          &:hover {
            cursor: default;
          }

          input[type="checkbox"] {
            border-color: #ddd;

            &:hover {
              cursor: default;
            }
          }
        }
      }
    }

    .fac-star-three-quarter, .fac-star-one-quarter {
      .star-icon {
        width: 20.4px;
      }
    }
  }

  .social-media-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
      margin-right: 5px;
      padding: 5px;

      &:last-child {
        margin-right: 0;
      }

      .hover {
        display: none;
      }

      &:hover {
        background: black;

        img {
          display: none;
        }

        .hover {
          display: block;
        }
      }

      img {
        width: 20px;
      }
    }
  }


  .filter-results-container {

    @media(min-width:768px) {
      display: flex;
    }

    .filters-column {
      width: 100%;
      padding: 0;
      margin-bottom: 15px;

      @media(min-width: 768px) {
        width: 25%;
        padding: 0 30px 0 0;
        margin-bottom: 0;
      }

      h3 {
        span {
          font-weight: 500;
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
      }

      .filter-group {
        margin: 0 0 24px;
      }
    }

    .results-column {
      padding-left: 0;
      width: 100%;

      @media(min-width: 768px) {
        width: 75%;
      }

      .reviews {
        .review {
          padding: 10px 0;
          border-bottom: 2px solid black;

          .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .rating {
              font-size: 24px;
              width: 50%;

              @media(min-width: 768px) {
                width: 30%;
              }
            }

            .author {
              font-size: 13px;
              width: 50%;
              text-align: right;

              @media(min-width: 768px) {
                width: 65%;
              }
            }
          }

          p {
            font-size: 15px;
          }
        }
      }
    }
  }

  .social-video-mobile {
    margin-top: 32px;

    .filter-group {
      margin-bottom: 24px;
    }
  }
`;

const mapMarker = css`
  background-color: #D40F7D;
  border: 2px solid #FFFFFF;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -9px;
  left: -9px;
  text-align: center;
  opacity: 0.9;
  z-index: 2;

  &.disabled {
    background-color: #959595;
    z-index: 1;
  }
`;

const stickyNav = css`
  margin-top: 0;
`;

const ShowMore = styled.div`
cursor: pointer;
margin-bottom: 20px;
div{
  width: max-content;
  padding: 10px;
  margin: auto;
  border: 2px solid black;

  i{
    margin-right: 5px;
  }
}
`

const MapMarker = ({disabled}) => {
  return (
    <div css={mapMarker} className={disabled ? 'disabled' : ''}></div>
  )
}

// requirements for how digitalData object should look like is in this ticket: https://owenscorning.atlassian.net/browse/OCR-6259
function updateDigitalData(contractor, reviewsWithComment) {
  let badgesCount = contractor.active_certifications.length
  let badgesInfo = badgesCount > 0 ? `2-${badgesCount}` : '0-0'
  let feedback = contractor.gq_member_questions.length > 0 ? '3' : '00'
  let location = (contractor.is_review_map_available && reviewsWithComment.length > 0) ? '4' : '00'
  let reviewRating = (contractor.gq_review_count && contractor.gq_review_stars) ? `5-${contractor.gq_review_count}-${reviewsWithComment.length}-${contractor.gq_review_stars}` : '0-0-0'
  let aboutus = contractor.aboutus ? '6' : '00'
  let social = (contractor.instagram ||
                contractor.pinterest ||
                contractor.facebook ||
                contractor.twitter ||
                contractor.linkedin ||
                contractor.youtube) ? '7' : '00'

  let contractorTrackingInfo = {
    page: {
      contractorDetails: {
        contentKey: `1:${badgesInfo}:${feedback}:${location}:${reviewRating}:${aboutus}:${social}`
      }
    }
  }

  mergeDigitalData(contractorTrackingInfo)
}

//initial state to load 5 comments
const initialState = {
  indexCount: 4
};
//reducer to dispatch 5 comments per click
const reducer = (state, action) => {
  if (action.type === 'loadMore') {
    return { indexCount: state.indexCount +  action.payload };
  }
};

const scrollToElement = (element_id) => {
  const navbarHeight = document.getElementsByClassName('stickynav-container')[0].offsetHeight;
  const element = document.getElementById(element_id);
  const elementPosition = element.offsetTop;
  window.scrollTo({top: elementPosition - navbarHeight, behavior: 'smooth'});
}


const ContractorProfile = FindAContractorConnector(connectLocation(({ locale: { code }, contractor, reviews, t }) => {
  const [badgeData, setBadgeData] = useState([])
  useEffect(() => {
    getBadgeData(code).then(data => setBadgeData(data))
  }, [])

  const {
    RAQOpen,
    RAQOnOpen,
    RAQOnClose,
    RAQFormName,
    RAQModalElementFunc
  } = useRAQQuestionModal();

  const activeBadges = contractor.active_certifications || []
  const [reviewOpen, setReviewModal] = useState(false);
  const [feedbackOpen, setFeedbackModal] = useState(false);
  const [commentsOpen, setCommentsModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);
  const [selectedRatings, setSelectedRatings] = useState([])
  const [state, dispatch] = useReducer(reducer, initialState);
  const hasReviews = (contractor.is_reviews_available);
  const phoneDigits = contractor.phone.replace(/[^0-9A-Z]/g, '');
  const formattedPhone = phoneDigits.replace(/(.{3})(.{3})(.{4,})/, '($1) $2-$3');

  const openedBadgeModal =
    (badgeData || []).filter(badge => badge.value == modalOpen)[0]

  const handleApiLoaded = (map, _maps) => {
    const bounds = new google.maps.LatLngBounds();
    reviews.map(review => {
      let loc = new google.maps.LatLng(review.latitude, review.longitude);
      bounds.extend(loc);
    })

    map.fitBounds(bounds);
    map.panToBounds(bounds);
  }

  updateDigitalData(contractor, reviews)

  const formatZip = (code, zip) => {
    if (code.split('-')[1] === 'CA') {
      return `${zip.slice(0, 3)} ${zip.slice(3)}`
    } else {
      return zip;
    }
  }

  return (
    <div css={facDetail}>
      <section className={hasReviews ? 'no-margin-bottom' : undefined}>
        <div className="contractor-header">
          <div className="logo">
            <If condition={contractor.logo}>
              <Image src={contractor.logo} alt={`${contractor.store_name} logo`} skipTransform={true}/>
            </If>
            <If condition={!contractor.logo}>
              <Image src="https://res.cloudinary.com/ocimages/image/upload/f_auto,w_130/v1602264874/roofing/contractors/house-generic.png" alt="" />
            </If>
          </div>

          <div className="contractor-info">
            <h1 className="h2 name">{contractor.store_name}</h1>

            <If condition={contractor.is_rating_available}>
              <div className="ratings-summary">
                <span className="ratings">
                  <Stars size="24" stars={contractor.gq_review_stars} />
                </span>
                <span className="review-count">
                  {`${contractor.gq_review_stars} | `}
                  {t('contractor_profile.review_count', { count: contractor.gq_review_count })}
                </span>
                <InfoIcon clickHandler={() => setReviewModal(true)} data-track="learn-more" data-track-destination="Star Ratings Modal"/>
              </div>
            </If>

            <div className="contact-info">
              <p><i className="fa fa-map-marker"></i> {contractor.address_line1} {contractor.address_line2} | {contractor.city}, {contractor.state} {formatZip(code, contractor.mas_dealer_zip)} </p>

              <p>
                <If condition={contractor.phone}>
                  <a href={`tel:${phoneDigits}`} className="phone" data-track="phone" data-track-professional="contractor" data-track-contractor-id={contractor.mas_dealer_code} data-track-contractor={contractor.store_name}><i className="fa fa-phone"></i>{formattedPhone}</a>
                </If>
                <If condition={contractor.email}>
                  <i className="fa fa-envelope"></i>
                  <WrappedEmailRAQQuestionModalButton contractor={contractor}>
                    {({ onClick }) => (
                      <a
                        className="link-text"
                        onClick={onClick}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === 'Space') {
                            onClick();
                          }
                        }}
                        data-track="open-modal"
                        data-track-modal-name="roof-replacement-modal"
                        data-track-form-name="email-contractor"
                        data-track-contractor={contractor.store_name}
                        data-track-contractor-id={contractor.mas_dealer_code}
                        tabIndex={0}
                      >
                        {t('contractor_profile.email')}
                      </a>
                    )}
                  </WrappedEmailRAQQuestionModalButton>
                </If>
              </p>

              <If condition={contractor.website}>
                <p>
                  <i className="fa fa-globe"></i>
                  <a href={contractor.website}
                    className="link-text"
                    data-track="website"
                    data-track-contractor={contractor.store_name}
                    data-track-contractor-id={contractor.mas_dealer_code}
                    data-track-professional="contractor"
                    tabIndex={0}
                  >
                    {contractor.website}
                  </a>
                </p>
              </If>
            </div>
          </div>

          <div className="cert-container">
            <div className="badges">
              <If condition={contractor.is_preferred_platinum}>
                <Image src={t('contractor_profile.platinum_icon')} alt={t('contractor_profile.platinum_title')}/>
              </If>
              <If condition={!contractor.is_preferred_platinum}>
                <Image src={t('contractor_profile.preferred_icon')} alt={t('contractor_profile.preferred_title')}/>
              </If>
            </div>

            <ul className="certifications">
              {
                badgeData.map(badge => {
                  if (activeBadges.filter(value => (Array.wrap(badge.value || [])).includes(value)).length > 0) {
                    return (
                      <li data-track="tooltip" data-track-destination={ badge.analyticsName }>
                        <Image
                          src={ badge.icon?.file }
                          alt={ badge.icon?.alt }
                        />
                        <span className="cert-info">
                          { badge.label }
                          <InfoIcon clickHandler={() => setModalOpen(badge.value)} />
                        </span>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })
              }
            </ul>
          </div>
        </div>
      </section>

      <div className="bg-full-bleed oc-stickynav" css={stickyNav} >
        <div className="stickynav-container">
            <RequestAQuoteModal
              source="profile"
              contractor={contractor}
              open={RAQOpen}
              portal={RAQModalElementFunc}
              onClose={RAQOnClose}
              raqFormName={RAQFormName}
            />

          <If condition={contractor.show_request_a_quote}>
            <div className="cta-group">
              <a className="oc-cta-button"
                 data-track="form-start"
                 data-track-form-type="lead"
                 data-track-form-name="request-a-quote"
                 data-track-contractor={contractor.store_name}
                 data-track-contractor-id={contractor.mas_dealer_code}
                 data-track-professional="contractor"
                 onClick={RAQOnOpen}
                 onKeyPress={(e) => {
                   if (e.key === 'Enter' || e.key === 'Space') {
                     RAQOnOpen();
                   }
                 }}
                 tabIndex={0}
              >
                {t('contractor_profile.request_quote')}
              </a>
            </div>
          </If>

          <ul id="stickynav-slider" className="stickynav-links">
            {/** StickyNav links are auto generated here based off the sections and whether or not they have a className of stickynav-section */}
          </ul>
        </div>
      </div>

      <If condition={contractor.is_rating_available && contractor.gq_member_questions.length > 0}>
        <section id="feedback" className="border stickynav-section" data-stickynav-title={t('contractor_profile.feedback')}>
          <h2 className="h4 section-heading">{t('contractor_profile.feedback')
          }<a style={{textDecoration: 'none',position: 'relative', cursor: 'pointer', marginRight: '4px', marginLeft: '2px', fontSize: '20px'}}  onClick={() =>scrollToElement('footnote1')}>¹</a>
           <InfoIcon size="24px" clickHandler={() => setFeedbackModal(true)} style={{position: 'relative'}} />
           </h2>

          <div className="columns four-up">

            {contractor.gq_member_questions.sort((q1, q2) => (q1.question_id - q2.question_id)).map(question => (
              <div className="col feedback" key={question.question_id}>
                <div className="score">
                  <img src={`https://res.cloudinary.com/ocimages/image/upload/f_auto/v1597770161/roofing/contractors/icons/icon-${question.gq_question.question_name.toLowerCase()}.svg`} alt=""/>
                  {Math.round(question.percentage)}<sup>%</sup>
                </div>

                <label>{question.gq_question.question_name}</label>
              </div>
            ))}

          </div>
        </section>
      </If>

      <If condition={contractor.is_review_map_available && reviews.length > 0 && contractor.latitude && contractor.longitude}>
        <section id="project-locations" className="border stickynav-section" data-stickynav-title={t('contractor_profile.project_locations')}>
          <div className="condensed-width-content">
            <h2 className="h3">{t('contractor_profile.project_locations')}</h2>

            <div id="map" className="project-map" style={{height: '400px'}} aria-label={t('contractor_profile.interactive_map')}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                defaultZoom={10}
                defaultCenter={[parseFloat(contractor.latitude), parseFloat(contractor.longitude)]}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              >
                {reviews.map(review => {
                  // If no ratings are selected all should be enabled
                  const disabled = !!selectedRatings.length && !selectedRatings.includes(parseInt(review.review_stars));
                  return(
                    <MapMarker lat={review.latitude} lng={review.longitude} key={review.id} disabled={disabled}/>
                  )
                })}
              </GoogleMapReact>
            </div>
          </div>
        </section>
      </If>

      <If condition={contractor.is_reviews_available && reviews.length > 0}>
        <section id="reviews" className="stickynav-section" data-stickynav-title={t('contractor_profile.reviews_with_comments')}>
          <div className="filter-results-container">
            <div role="complementary" aria-label="Filters Sidebar" id="filters" className="col filters-column">
              <h3 className="h4 body-font">{t('contractor_profile.total_ratings')} <span>({contractor.gq_review_count})</span> <InfoIcon clickHandler={() => setReviewModal(true)} /></h3>

              <div className="filter-group">
                <p><strong>{t('contractor_profile.all_reviews_with_comments')} {reviews.length}</strong></p>

                <ul className="ratings">
                  {[5,4,3,2,1].map(rating => {
                    const count = reviews.filter(r => r.review_stars == rating).length;
                    const disabled = count === 0 ? 'disabled' : '';
                    const selected = selectedRatings.includes(rating);
                    const onChange = () => {
                      if(selected) {
                        setSelectedRatings(selectedRatings.filter(sr => sr !== rating))
                      } else {
                        setSelectedRatings([...selectedRatings, rating])
                      }
                    }

                    return (
                      <li className={disabled} key={rating}>
                        <label tabIndex={disabled ? -1 : "0"}
                          onKeyPress={(event) => {

                              if (event.charCode == "13") {
                                onChange();
                              }
                            }}
                        >
                          <input
                            data-track="filter"
                            data-track-filter-name="ratings"
                            data-track-filter-option={`${count} stars`}
                            tabIndex='-1'
                            type="checkbox"
                            disabled={disabled}
                            checked={!disabled && selected}
                            onChange={onChange}
                            readOnly={disabled ? 'readonly' : null}
                          />
                          <span className="check"></span>

                          <Stars stars={rating} />
                          <span>({count})</span>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="tablet-desktop-only">
                <SocialLinks contractor={contractor} />
                <AboutUs contractor={contractor} />
                <DealerVideos contractor={contractor} />
              </div>
            </div>

            <div id="searchresults" aria-label="results column" className="col results-column">
              <h2 className="h3">{t('contractor_profile.reviews_with_comments')}<a onClick={() =>scrollToElement('footnote2')} style={{textDecoration: 'none'}}>²</a> <InfoIcon size="31px" clickHandler={() => setCommentsModal(true)} /></h2>
              <p>{t('contractor_profile.recent_reviews')}</p>

              <div className="reviews">
                {reviews.map((review, index )=> {
                  // If no ratings are selected all should be enabled
                  const disabled = !!selectedRatings.length && !selectedRatings.includes(parseInt(review.review_stars));

                  if(disabled) return;
                  if(index > state.indexCount) return;
                  return (
                    <div className="review" key={review.id}>
                      <div className="heading">
                        <span className="rating">
                          <Stars stars={review.review_stars}/>
                        </span>

                        <span className="author">{review.contact_name} {t('contractor_profile.of')} {review.project_city}, {review.project_state} | {review.completion_date}</span>
                      </div>

                      <p>{review.review_text}</p>
                    </div>
                  )
                })}

              </div>

               {state.indexCount < reviews?.length && <ShowMore>
                  <div class="text-center"
                  onClick={() => dispatch({ type: 'loadMore', payload: 5 })}
                  >
                    <span class="cta cta--secondary cta--black show-hide-reviews" data-status="hiding">
                     <><i class="fa fa-plus mrs"></i>{t('contractor_profile.more_review_button')}</>
                    </span>
                  </div>
              </ShowMore>}
              <div className="mobile-only social-video-mobile">
                <SocialLinks contractor={contractor} />
                <AboutUs contractor={contractor} />
                <DealerVideos contractor={contractor} />
              </div>
            </div>
          </div>
        </section>
      </If>

      <ol class="text-right">
             <If condition={contractor.is_rating_available && contractor.gq_member_questions.length > 0}>
                <li>
                  <p className="medium">{t('contractor_profile.feedback_scores')} <a
                  id='footnote1' class="fa fa-long-arrow-left" onClick={() =>scrollToElement('feedback')} >
                    </a> </p>
                </li>
             </If>
             <If condition={contractor.is_reviews_available && reviews.length > 0}>
                <li>
                  <p className="medium">
                    {t('contractor_profile.reviews_count', {count: reviews.length})} <a  id='footnote2' onClick={() =>scrollToElement('reviews')} class="fa fa-long-arrow-left"></a> <br />
                    {t('contractor_profile.for_all_reviews')}&nbsp;
                    <a href={_.get(contractor, 'gq_member.gq_profile_url', '#')}>{t('contractor_profile.click_here')}</a>
                    &nbsp;{t('contractor_profile.visit_contractor_profile')}
                  </p>
                </li>
             </If>
      </ol>

      <If condition={!contractor.is_reviews_available || reviews.length === 0}>
        <section>
          <AboutUs contractor={contractor} />
          <div className="filter-results-container">
            <div className="col filters-column">
              <SocialLinks contractor={contractor} />
              <DealerVideos contractor={contractor} />
            </div>
          </div>
        </section>
      </If>

      <Modal open={reviewOpen} onClose={() => setReviewModal(false)}>
        <h2 className="h3">{t('contractor_profile.review_modal.title')}</h2>

        <p>{t('contractor_profile.review_modal.star_ratings_explanation')}</p>
        <p>{t('contractor_profile.review_modal.guild_quality_explanation')}</p>
        <a href="https://pages.guildquality.com/partner/owenscorning/" className="text-link text-link--primary-chevron-right">{t('contractor_profile.learn_more')} <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
      </Modal>

      <Modal open={feedbackOpen} onClose={() => setFeedbackModal(false)}>
        <h2 className="h3">{t('contractor_profile.feedback_modal.title')}</h2>

        <p>{t('contractor_profile.feedback_modal.first_line')}</p>
        <p>{t('contractor_profile.feedback_modal.second_line')}</p>
        <a href="https://pages.guildquality.com/partner/owenscorning/" className="text-link text-link--primary-chevron-right">{t('contractor_profile.learn_more')} <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
      </Modal>

      <Modal open={commentsOpen} onClose={() => setCommentsModal(false)}>
        <h2 className="h3">{t('contractor_profile.comments_modal.title')}</h2>

        <p>{t('contractor_profile.comments_modal.first_line')}</p>
        <p>{t('contractor_profile.comments_modal.second_line')}</p>
        <a href="https://www.guildquality.com/about/ratings/" className="text-link text-link--primary-chevron-right">{t('contractor_profile.learn_more')} <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
      </Modal>

      {
        openedBadgeModal &&
          <ContractorBadgeModal open details={ openedBadgeModal.details } onClose={ () => setModalOpen(null) } />
      }

    </div>

  );
}));

ContractorProfile.WrappedComponent.displayName = 'ContractorProfile';

export default ((props) => (
  <Provider store={ ReactOnRails.getStore("FindAContractorStore") }>
    <ContractorProfile {...props}/>
  </Provider>
))
