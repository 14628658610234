import {jsx} from '@emotion/react';
import { Component, useState } from 'react';
import {Provider} from "react-redux";
import _ from 'lodash';
import {connectLocation} from "../location/LocationConnectors";
import {FindAContractorConnector} from "../../redux/connectors/FindAContractorConnector";
import {FACSidebar} from "./Sidebar";
import FACResults from "./Results";
import {ContractorBadgeModal} from "../CertificationsModal";
import ReactOnRails from "react-on-rails";
import querystring from 'qs';
import { getBadgeData } from './ContractorBadges';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import responseTransform from './responseTransform';
import { connectFeatureFlag } from '../FeatureFlag/FeatureFlagConnectors';

class XFAC extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modalOpen: null,
      badgeData: null,
      isMobileOnly: false,
    }
  }

  componentDidMount() {
    getBadgeData(this.props.locale.code).then(badgeData => {
      this.setState({ badgeData })
    });
    this.setState({
      isMobileOnly: window.matchMedia('(hover: none) and (pointer: coarse)')
        .matches
    });
  }

  componentDidUpdate(prevProps) {
    const distanceChanged = (prevProps.maxDistance !== this.props.maxDistance) && !this.props.autoExpandedRange;
    const sortChanged = prevProps.sortBy !== this.props.sortBy;
    if (distanceChanged || sortChanged) {
      this.load();
    }
  }

  openModal(modalHash) {
    this.setState({
      modalOpen: modalHash
    });
  }

  closeModal() {
    this.setState({
      modalOpen: null
    })
  }

  fetchContractorLocations() {
    const {
      city,
      region,
      latitude: lat,
      longitude: lng,
    } = this.props.address;
    const { maxDistance: radius, sortBy: sort, locale, enabledFeatureFlags } = this.props;
    const params = {
      lat,
      lng,
      radius,
      sort,
      per_page: 10000, // api requires a per_page. 100 should be fine
    };

    const toolseyRaqEnabled = enabledFeatureFlags.includes("toolsey_raq") && locale.countryName === "United States";

    const query = querystring.stringify(params);
    return fetch(`${MDMS_URL}/api/v3/locations/roofing-contractors?${query}`)
      .then(response => response.json())
      .then(response =>
        responseTransform({
          response,
          radius,
          city,
          region,
          toolseyRaqEnabled,
        })
      );
  }

  fetchContractors() {
    const params = _.merge(
      {
        radius: this.props.maxDistance,
        sort: this.props.sortBy,
        auto_expand: 0,
        c_code: this.props.locale.code,
      },
      this.props.cityToken
        ? {
            state: this.props.stateToken,
            city: this.props.cityToken,
          }
        : {
            zip: this.props.zip,
          }
    );

    const query = querystring.stringify(params);
    return fetch(
      `${MDMS_URL}/api/v2/roofing/contractors?${query}`
    ).then(response => response.json());
  }

  load() {
    const { updateAPI } = this.props;
    this.fetchContractorLocations().then(updateAPI);
  }

  render() {
    const openedBadgeModal = _.isPlainObject(this.state.modalOpen) ?
      this.state.modalOpen :
      (this.state.badgeData || []).filter(badge => badge.value == this.state.modalOpen)[0]

    return (
      <>
        <div className="columns ratio-1-3 tablet-stack">
          <div className="col">
            <FACSidebar
              badgeData={this.state.badgeData}
              bot={!!this.props.bot}
              openModal={this.openModal.bind(this)}
              isMobileOnly={this.state.isMobileOnly}
            />
          </div>
          <div className="col">
            <FACResults badgeData={this.state.badgeData} bot={!!this.props.bot} openModal={this.openModal.bind(this)} />
          </div>

          {
            openedBadgeModal &&
              <ContractorBadgeModal open details={ openedBadgeModal.details } onClose={this.closeModal.bind(this)} />
          }
        </div>
      </>
    )
  }
}

const FAC = FindAContractorConnector(connectLocation(connectFeatureFlag(XFAC)));

export default (props) => (
  <Provider store={ ReactOnRails.getStore("FindAContractorStore") }>
    <FAC {...props}/>
  </Provider>
)
