/* eslint-disable camelcase */

const dontEmailRegex = /donote?mail/i;

function transformContractor(contractor, toolseyRaqEnabled) {
  const is_reviews_available = contractor.gq_review_count > 0 && contractor.gq_review_stars > 3.0;
  const has_email = contractor.email && !dontEmailRegex.test(contractor.email);

  return {
    mas_dealer_code: contractor.membership_number,
    mas_dealer_zip: contractor.postal,
    store_name: contractor.name,
    address_line1: contractor.address,
    address_line2: contractor.address_2,
    city: contractor.city,
    state: contractor.state,
    website: contractor.website,
    phone: contractor.phone,
    is_preferred_platinum: contractor.tier_level === 3,
    gq_review_stars: contractor.gq_review_stars,
    gq_review_count: contractor.gq_review_count,
    is_reviews_available,
    is_review_map_available: is_reviews_available,
    is_rating_available: is_reviews_available,
    show_request_a_quote: toolseyRaqEnabled ? contractor.show_request_a_quote : has_email,
    has_email,
    latitude: contractor.latitude,
    longitude: contractor.longitude,
    active_certifications: contractor.badges,
    logo: contractor.logo,
  };
}

// same as the transform_to_v2_response helper in ruby, but in JS
export default function responseTransform({ response, radius, city, region, toolseyRaqEnabled }) {
  return {
    radius,
    meta: {},
    contractors: response?.data?.map(contractor => transformContractor(contractor, toolseyRaqEnabled)),
    city,
    region,
  };
}
